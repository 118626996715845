﻿* {
    margin: 0;
    padding: 0;
}
html {
    height: 100%;
}
body {
    font-size: 14px !important;
    height: 100%;
    overflow: hidden;
}


.errorMessage {
    color: red;
    text-align: left;
    padding-bottom: 12px;
    
}
header #mainNav {
    height: 55px;
    padding-top: 10px;
    overflow: visible;
}

section#mainApplication {
    position: absolute;
    top: 50px;
    width: 100%;
    bottom: 29px;
    /*background-image: url(../Images/logo-colored.png);*/
    background-repeat: no-repeat;
    background-position: center center;
    display:flex;
  
}



.form-row {
    margin-top: 20px;

    
}

.report-form-row-label {
    display: inline-block;
    width: 80px;
}
.report-form-row-field {
    display: inline-block;
    width: 180px;
}

#quoteView {
    overflow-y: hidden;
    padding: 5px;
    /*display: flex;
    flex-flow: row wrap;
    padding-bottom: 5px;*/
}
#tradeView {
    overflow-y: hidden;
    padding: 5px;
    /*display: flex;
    flex-flow: row wrap;
    padding-bottom: 5px;*/
}

#tradeAndQuoteView {
    overflow-y: hidden;
    padding: 5px;
    /*display: flex;
    flex-flow: row wrap;
    padding-bottom: 5px;*/
}


#marketDataSessionsGrid
{
 position: relative;
}
#reportingGridContainer {
    overflow-y: hidden;
    padding: 5px;   
}



#userSessionsView {
    overflow-y: hidden;
    padding: 5px;   
}

#webSocketGatewayView {
    overflow-y: hidden;
    padding: 5px;   
}

/* #marketDataSessionsGrid {
    flex-shrink: 0;
    width: 100%;
}

#marketDataSubscriptionsGrid {
    flex: 1;
    margin-left: 10px;
} */


#positionsAuditView {
    overflow-y: hidden;
    padding: 5px;
  
}

#orderBookView {
    overflow-y: hidden;
    padding: 5px;
}

#marketBoardView {
    display: flex;
    flex-direction: row;
    width: 100%;
}

#marketBoard {
    flex: 1;
    overflow-y:auto;
}

.showDropDown {
    display: none;
    position: fixed;
    margin: 2px;
    width: 150px;
    z-index: 99999;
    font-size: 13px;
}

.marketBoxHeader:hover .showDropDown {
    display: block;
}  


#barsView {
    overflow-y: hidden;
    padding: 10px;
}

/*#chart {
    position: fixed;
    top: 255px;
    left:13px;
    right: 11px;
    bottom: 41px;
    background-color: yellow;
    border: 1px solid red;
}*/

#priceHistoryView {
    padding: 10px;
    overflow-y: hidden;
    display: flex;
    flex-direction: row;
}

#tradeHistoryGrid {
    flex-shrink: 0;
    width: 44%;
}

#quoteHistoryGrid {
    flex: 1;
    margin-left: 10px;
}


#endpointLogView {
    padding: 10px;
    overflow-y: hidden;
    display: flex;
    flex-direction: row;
    width: 100%
}

#endpointLogGrid {
    flex-shrink:0;
    width: 1100px;
}

#endpointRequestEditor {
    flex: 1;
    margin-left: 10px;
}

/*#endpointTestViewContainer {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
}

#endpointInputs {
    height: 30px;
    margin-bottom: 15px;
}

#endpointResponse {
    flex: 1;
}

#endpointResponseEditor {
    height: 100%;
}*/



#tradingView {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;

}

#summaryGridContainer {
    flex-shrink: 0;
    height: 90px;
    background-color: deeppink;
    padding-bottom: 10px
}

#positionsGridContainer {
    background-color: aqua;
    flex: 1;
    padding-bottom: 10px;
}



#rawTitleEditor {
    height: 100%;
    display: flex;
    padding: 0;
    margin: 0;
}

#rawDataView {

    height: 100%;
    display: flex;
    padding: 0;
    margin: 0;
}

#rawData {
    flex-basis: 100%;
}

#rawDataEditor {
    height: 100%;
}

#statusBar {
    position: fixed;
    width: 100%;
    bottom: 0;
    height: 30px;
    max-height: 30px;
}

#statusBarContainer label {
    display: inline;
    line-height: 30px;
}

#statusBarContainer span {
    display: inline;
    line-height: 30px;
}

#loginModal {
    border: 2px solid;
    border-color: grey;
    padding: 0px !important;
    background-color: black;
}

#loginContainer {
   
    margin: 0 auto;
    width: 325px;
    height: auto;
    text-align: center;
    color:white;
       
}

#loginBranding {
    margin-top: 10px;
    margin-bottom: 20px;
    display: block;
    text-align: center;
}

#loginFormContainer {
    padding-bottom: 20px;
    display: inline-block;
}

#loginFormContainer .k-textbox{
    width: 150px;
    background-color:  #157299 !important;

}

.login-textbox {
    
    display: inline;
}

.textbox-labels {
    display: inline-block;
    width: 60px;
    margin-right: 10px;
}

.login-submit {
    margin-left: 170px;
}

.login-submit button {
    cursor: pointer;
}

.login-submit button:first-letter {
    text-decoration: underline;
}


.grid-data-center
{
    text-align:center !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.grid-data-right
{
    text-align:right !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.grid-data-left 
{
    text-align:left;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.grid-header-center
{
  
    height:25px !important;
    text-align:center !important;
    padding: 0px !important;


}
.k-grid td {
    line-height: 3em !important;
    height: 3em;
}
.k-grid {
    font-size: 1em;
}

.k-grid td {
    line-height: 1.5em;
}

.k-grid th { 
    line-height: 1.5em;
}

.k-toolbar {
    min-height: 30px;
}

.k-grid .k-dirty {
    display: none;
}
div.k-grid-header thead th.k-header{
line-height: 10px;
height: 10px;
}

.no-scrollbar .k-grid-header {
    padding: 0 !important;
}

.no-scrollbar .k-grid-content {
    overflow-y: visible;
    overflow-x:visible;
}

.toolbar-margins {
    margin-right: 5px;
    margin-bottom : 5px;
    margin-top : 5px;
   
}

.positiveColor {
    color: #157299 !important;
}

.negativeColor {
    color: red !important;
}

.unchangedColor {
    color: black !important;
}

.summaryInputContainer{
    display: inline-block;
}

.chartToolbar{
    display: flex;
    flex-flow: wrap;
    align-items: center;
    text-indent: 5px;
}

.commandToolbar{
    display: flex;
    flex-flow: wrap;
    align-items: center;
    text-indent: 5px;
}

#reportingToolbar .k-toolbar-first-visible{
    width: 100%;
}
#reportingToolbar{
    padding: 0;
    display: inline-table;
}

#commandToolbar .k-toolbar-first-visible{
    width: 100%;
}
#commandToolbar{
    padding: 0;
    display: inline-table;
}

/* #reportingGrid1, #reportingGrid2{
    height: calc(100% - 10px) !important;
    display:  flex;
    flex-direction: column;
}

#reportingGrid1 .k-auto-scrollable, #reportingGrid2 .k-auto-scrollable{
    overflow-y: auto;
    height: unset !important;
    flex: 1 1 0%;
} */

#reportingGridContainer{
    overflow: hidden;
}

.k-tabstrip-wrapper{
    height: 100%;
}

.symbol-logo {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-size: 24px 28px;
    background-position: center center;
    vertical-align: middle;
    line-height: 24px;
    box-shadow: inset 0 0 1px #999, inset 0 0 10px rgba(0,0,0,.2);
    margin-right: 5px;
}


#microProxyToolbar .k-toolbar-first-visible{
    width: 100%;
}
#microProxyToolbar{
    padding: 0;
    display: inline-table;
}

.microProxyContentItem{
    display: flex;
    flex-direction:column;
    position:relative; 
    border:2px solid black;
    height: calc(100% - 40px);
    width: calc(100% - 40px);
    padding: 10px;
    margin-top: 10px;
}
.microProxyContentItem :first-child{
    border: 1px solid grey;
}

.microProxyContentHeader{
    position:absolute;
    top: -10px;
    left: 7px;
    background: white;
    padding: 0px 5px;
}